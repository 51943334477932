$("#home a:contains('Home')").addClass('active');
$("#about a:contains('About')").addClass('active');
$("#staff a:contains('Staff')").addClass('active');
$("#resources a:contains('Resources')").addClass('active');
$("#cello-month a:contains('Cello')").addClass('active');
$("#contact a:contains('Contact')").addClass('active');
$("#privacy a:contains('Privacy')").addClass('active');
$("#accessibility a:contains('Accessibility')").addClass('active');


